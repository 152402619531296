import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/SEO/SEO"
import GridSystem from "../components/GridSystem"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import Pagination from "../components/Pagination/Pagination"
import { motion } from "framer-motion"

import "../components/work.css"

import {
  Wrapper,
  ContentWrapper,
  PageContent,
  StyledH2,
  StyledDate,
  StyledReadMore,
} from "./work-archive.styles"
import { Container } from "react-bootstrap"

export const Head = () => <SEO title="Work / Portfolio" />

const workArchiveTemplate = ({
  data: { allWpWork },
  pageContext: { baseUri, numPages, currentPage },
}) => (
  <Layout>
    <Wrapper>
      <ContentWrapper>
        <article>
          <h1>Work</h1>
          <p>Welcome to our portfolio</p>
          <Link to="/">Go back to the homepage</Link>
        </article>
      </ContentWrapper>
    </Wrapper>

    <div className="grid-container">
      <GridSystem colCount={2} md={6}>
        {allWpWork.edges.map(({ node }) => (
          <div key={node.slug} className={"post"}>
            <Link to={node.uri}>
              <motion.img
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.02,
                }}
                alt={node.title}
                className="featured-image"
                src={node.featuredImage.node.mediaItemUrl}
              />
            </Link>
            <Link to={node.uri}>
              <h3 className="work-title-link">{node.title}</h3>
            </Link>
          </div>
        ))}
      </GridSystem>
    </div>
    <Pagination baseUri={baseUri} page={currentPage} totalPages={numPages} />
  </Layout>
)

export default workArchiveTemplate

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpWork(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          title
          slug
          uri

          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
